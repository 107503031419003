<template>
  <div class="relative z-50">
    <button
      v-tooltip="tooltipText"
      class="p-1 py-3 flex justify-center items-center w-10 rounded-md mr-2 cursor-pointer h-[36px]"
      :class="{ 'bg-blue-100': showMenu }"
      @click.stop="showMenu = true"
    >
      <icon-base
        height="20"
        width="20"
        :icon="buttonIcon"
        icon-color="#3769D6"
        class="ml-1"
        :data-testid="buttonIcon"
      />
    </button>
    <div
      v-if="showMenu"
      v-on-click-outside="onClickOutsideHandler"
      class="absolute top-11 -right-[40px] shadow-xl bg-white w-72 py-4 visibility-menu"
    >
      <div class="pl-4 pr-6">
        <lf-h3>{{ $t("DEALS.DEAL_NOTES.WHO_CAN_SEE_NOTE") }}</lf-h3>
        <div class="mt-2">
          {{ $t("DEALS.DEAL_NOTES.CHOSE_GROUP_MEMBERS") }}
        </div>
        <div class="mt-3">
          <div
            v-for="(value, key) in NOTE_VISIBILITY_SCOPES"
            :key="key"
            class="flex items-center mb-2 text-gray-800 cursor-pointer"
            @click.stop="setPrimaryVisibility(key)"
          >
            <icon-with-checkmark
              class="mr-2"
              :selected="selectedButton === Number(key)"
            >
              <icon-base
                height="18"
                width="18"
                :icon="iconMap[value.icon].icon"
                icon-color="white"
                class="ml-1"
                :view-box="iconMap[value.icon].viewBox || '0 0 20 14'"
              />
            </icon-with-checkmark>
            <span>{{ value.text }}</span>
          </div>
        </div>
        <div class="ml-10">
          <div
            v-for="(value, key) in allowedOtherVisibilityScopes"
            :key="key"
            class="mb-2"
          >
            <input
              :id="String(key)"
              v-model="selectedVisibility"
              type="radio"
              :value="key"
              name="visibility-scope"
            />
            <label :for="String(key)" class="ml-1 text-gray-800">
              {{ value }}
            </label>
          </div>
        </div>
      </div>
      <div
        v-if="isOtherOptionSelected"
        class="border-t border-gray-150 pl-4 pr-6 mt-4"
      >
        <primary-button class="w-full mt-4" @click.stop="submit">
          {{ $t("COMMON.APPLY") }}
        </primary-button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Component, PropType } from "vue";
import { computed, ref, watch } from "vue";

import {
  NOTE_VISIBILITY_SCOPES,
  OTHER_GROUPS_VISIBILITY,
  ALL_NOTE_VISIBILITY_TYPES,
  EXTERNAL_FUNDERS_KEY
} from "@/helpers/constants";
import { useAuth } from "@/hooks/auth";
import IconWithCheckmark from "@/views/deals/components/dealNotes/IconWithCheckmark.vue";
import type {
  noteVisibilityScope,
  primaryVisibilityScope
} from "@/models/common";
import omit from "lodash/omit";

import IconPeople from "@/components/icons/IconPeople.vue";
import IconUser from "@/components/icons/IconUser.vue";
import IconStar from "@/components/icons/IconStar.vue";

const iconMap: Record<string, Record<string, Component | string>> = {
  people: { icon: IconPeople, viewBox: "2 0 20 20" },
  user: { icon: IconUser },
  star: { icon: IconStar }
};

const props = defineProps({
  value: {
    type: Number as PropType<noteVisibilityScope>,
    required: true
  }
});

const emit = defineEmits(["changeScope"]);

const { isClient, isFunder } = useAuth();

const showMenu = ref(false);
const selectedVisibility = ref<noteVisibilityScope>(props.value);

const selectedButton = computed<primaryVisibilityScope>(() => {
  if (selectedVisibility.value === 1 || selectedVisibility.value === 3) {
    return selectedVisibility.value;
  }
  return 99;
});

const buttonIcon = computed(
  () => iconMap[NOTE_VISIBILITY_SCOPES[selectedButton.value].icon].icon
);

const tooltipText = computed(
  () => `visibility: ${ALL_NOTE_VISIBILITY_TYPES[selectedVisibility.value]}`
);

const isOtherOptionSelected = computed(() => selectedButton.value === 99);

const otherVisibilityScopes = computed(() => {
  if (isClient) {
    return OTHER_GROUPS_VISIBILITY.CLIENT;
  }
  if (isFunder) {
    return OTHER_GROUPS_VISIBILITY.FUNDER;
  }
  return OTHER_GROUPS_VISIBILITY.LENDFLOW;
});

const allowedOtherVisibilityScopes = computed(() =>
  omit(otherVisibilityScopes.value, [EXTERNAL_FUNDERS_KEY])
);

const setPrimaryVisibility = (key: noteVisibilityScope | 99) => {
  if (Number(key) !== 99) {
    selectedVisibility.value = Number(key) as noteVisibilityScope;
    emit("changeScope", selectedVisibility.value);
    showMenu.value = false;
    return;
  }
  selectedVisibility.value = Number(
    Object.keys(allowedOtherVisibilityScopes.value)[0]
  ) as noteVisibilityScope;
};

const submit = () => {
  showMenu.value = false;
  emit("changeScope", selectedVisibility.value);
};

const onClickOutsideHandler = [
  () => {
    selectedVisibility.value = props.value;
    showMenu.value = false;
  },
  { capture: false }
];

watch(
  () => props.value,
  () => {
    selectedVisibility.value = props.value;
  }
);
</script>
<style>
@container (max-width: 300px) {
  .visibility-menu {
    width: 250px;
  }
}
</style>
